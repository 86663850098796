require('./common/accordion');

(function ($) {
  objectFitImages();

  var $homeCover = $('#home_cover');
  $homeCover.owlCarousel({
      items: 1,
      loop:true,
      autoplay: true,
      autoplayTimeout: 3800,
      smartSpeed: 800,
  });

  var header = $('.m-panel-toggle'),
      btnToggle = $('button.toggle-nav');

  btnToggle.on('click', function () {
      header.toggleClass('active');
  });

  $("#back-top").hide();
  $(function () {
      $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
              $('#back-top').fadeIn();
          } else {
              $('#back-top').fadeOut();
          }
      });
          
      $('#back-top a').click(function () {
          $('body,html').animate({
              scrollTop: 0
          }, 800);
          return false;
      });
  });

  $(window).on('scroll',function(){
      var pos = window.scrollY;
      if(pos >= 100){
          $('.site-header').addClass('mini');
      }else {
          $('.site-header').removeClass('mini');
      }
  });

  var $grid = $('.grid').isotope({
      itemSelector: '.element-item',
  });
  var filterFns = {};
  $('#filters').on('click', 'li', function () {
      var filterValue = $(this).attr('data-filter'); 
      filterValue = filterFns[filterValue] || filterValue;
      $grid.isotope({
          filter: filterValue
      });
  });
  
  $('.button-group').each(function (i, buttonGroup) {
      var $buttonGroup = $(buttonGroup);
      $buttonGroup.on('click', 'li', function () {
          $buttonGroup.find('.is-checked').removeClass('is-checked');
          $(this).addClass('is-checked');
      });
  });
  
  $grid.imagesLoaded().progress(function(){
      $grid.isotope('layout');
  });
  /*** gallery */
  $grid.magnificPopup({
      delegate: 'a.accommodation',
      type: 'image',
      gallery: {
          enabled: true
      }
  });

  popupFilter('.element-item');

  $('.filter-tabs').on('click', 'li', function() {
      var filter = this.dataset.filter;
      popupFilter(filter);
  });

  function popupFilter(category) {
      $grid.magnificPopup({
          delegate: category,
          type: 'image',
          gallery: {
              enabled: true
          }
      });
  }

  /***---- booking  ------- */
  $('#booking_bar').booking({
      checkInSelector: '[name="checkin"]',
      checkOutSelector: '[name="checkout"]',
      adultSelector: '[name="numofadult"]',
      childSelector: '[name="numofchild"]',
      roomSelector: '[name="numofroom"]',
      codeSelector: '[name="accesscode"]',
      dateFormat: "dd M yy",
      submitSelector: ".btn-submit",
      propertyId: 411,
      onlineId: 4,
  });
  /***----- end ------ */   

  //
  // Member
  //
  var memberCode = 'member';

  if (memberChecker()) {
      createMemberUI(); 
      
  } else {
      createGuestUI();
  }

  $('#form_member, #form_member_mobile').on('submit', function(event) {
      event.preventDefault();
      var email = $(this).find('[name="email"]').val();

      memberSendData({
          formAPI:    'e171e751ac1200026697997919eee3ab',
          formType:   2,
          customer: {
              email: email,
          }
      });

      memberCreateCookies({
          code: 'member',
          expire: 3,
          user: {
              email: email,
          }
      });

      var url = 'https://reservation.travelanium.net/propertyibe2/?propertyId=411&onlineId=4&accesscode=member';
      window.open(url);
      
      createMemberUI();
      
      return false;
  });

  function createMemberUI() {
      memberAppendCode('member');
      $('.mobile-btn-member, .desktop-btn-member').addClass('is-member').removeClass('is-guest');
      $('#collapseMember, #collapseMemberMobile').hide();
      $('#booking_bar').booking('update', 'secretCode', memberCode);
  }

  function createGuestUI() {
      $('.mobile-btn-member, .desktop-btn-member').addClass('is-guest').removeClass('is-member');
  }

  /***-------contact send mail  ------*/
  var $contactForm = $('#contact_form');
  var $contactFormResult = $contactForm.find('.form-result');
  $.validate({
      form: $contactForm,
      modules: 'html5, security',
      onSuccess: function($form){
          var form    = $form.get(0);
          var url     = form.action;
          var data    = $form.serializeArray();
          var page    = form.baseURI;

          data.push({
              name: 'base',
              value: page,
          });
          $form.addClass('form-sending');
          var sendform = tlForm({
              key: '8b27d981ac120002074a0149d2fcda67',
              data: {
                  name: $form.find('[name="name"]').val(),
                  email: $form.find('[name="email"]').val(),
                  message: $form.find('[name="message"]').val(),
                  countryCode: $form.find('[name="country"]').val(),
                  countryName: $form.find('[name="country"] > option:selected').text(),
              },
              complete: function(){
                  $form.removeClass('form-sending');
              }
          });

          if (!sendform) {
              displayResult($contactFormResult, 'alert-success', 'Thank You! Your information has been sent and we should be in touch with you soon.');
              form.reset();
          } else {
              displayResult($contactFormResult, 'alert-danger', 'Message cannot be sent. Please contact directly to <a class="alert-link" href="mailto:info@paripaspatongresort.com">info@paripaspatongresort.com</a> <br/>We apologize for any inconvenience.');
          }

          return false;
      }
  });

  function displayResult(selector, addClass, message) {
      var $el = $(selector);
      $el.removeClass('alert-success alert-danger')
         .addClass(addClass)
         .html(message)
         .fadeIn();
  }    
  /**------------contact send mail -----------------*/
  
  $.validate({
      form: '#subscribe_form',
      modules: 'html5, security',
      onSuccess: function( $form ) {
          var form = $form.get(0);
          var fromURL = form.baseURI;
          var formAction = form.action;
          var formData = $form.serializeArray();
          // var formData = $form.serialize() + '&url=' + fromURL;

          formData.push({
              name: 'base',
              value: fromURL,
          });

          var result = $form.find('.result');
          var txtSuccess = 'Subscription Successful';
          var txtFailed = 'Subscription Failed! ...';

          // $.post( formAction, formData, function( data, status, jqXHR ) {
          //     switch( status ) {
          //         case 'success':
          //             $form.addClass('success')
          //                  .removeClass('failed')
          //                  .find(':focus').blur();
          //             result.hide();
          //             result.html(txtSuccess).slideDown();
          //             form.reset();
          //             break;
          //         case 'error':
          //             $form.addClass('failed')
          //                  .removeClass('success');
          //             result.hide();
          //             result.html(txtFailed).slideDown();
          //     };
          // });

          var sendform = tlForm({
              key: '8b291adcac12000243190d54fa482824',
              data: {
                  email: $form.find('[name="s-mail"]').val(),
              },
          });

          if (!sendform) {
              $form.addClass('success')
                   .removeClass('failed')
                   .find(':focus').blur();
              result.hide();
              result.html(txtSuccess).slideDown();
              form.reset();
          } else {
              $form.addClass('failed')
                   .removeClass('success');
              result.hide();
              result.html(txtFailed).slideDown();            
          }

          return false;
      },
      onError: function( $form ) {
          var form = $form.get(0);
          var result = $form.find('.result');
          result.hide();
      },
  });

})(jQuery);