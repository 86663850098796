(function ($) {
    var $acd = $('.accordion');
    var $acdi = $acd.find('.item');
    var $acdc = $acd.find('.content');
    var slideTitle = $('.carousel-container .title');

    var acdHi = $acd.height();
    var tabsHi = 0;
    $acd.find('.tab').each(function () {
        tabsHi += $(this).outerHeight();
    });
    $acdc.outerHeight(acdHi - tabsHi);

    function activeTab(index, speed) {
        $acdi.eq(index).addClass('active').siblings().removeClass('active');
        $acdi.eq(index).find('.content').slideDown(speed);
        $acdi.eq(index).siblings().find('.content').slideUp(speed);

        var getTitle = $acdi.eq(index).find('.title').text();
        slideTitle.text(getTitle);
    }

    activeTab(0, 0);

    $('.accordion').on('click', '.tab', function () {
        var index = $(this).parent('.item').index();
        activeTab(index, 200);

        $('#activity_carousel').trigger('to.owl.carousel', index);
    });

    $('#activity_carousel').owlCarousel({
        items: 1,
        loop: true,
        pullDrag: false,
        dots: true,
        smartSpeed: '800',
        onTranslate: function (e) {
            var index = e.item.index;
            var realIndex = $(e.currentTarget).find('.owl-item').eq(index).find('img').data('index');
            activeTab(realIndex);
        },
    });

    
})(jQuery);